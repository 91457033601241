import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import { OutcomeResults } from '@msslib/models/enums/outcome-results';
import { downloadBlob } from '@msslib/helpers/url-helpers';
import { BrokerOutcome } from 'apps/clubhub/src/app/ignite/models';
import { CriteriaStep } from 'apps/clubhub/src/app/ignite/models/affordability';
import { keys } from 'apps/clubhub/src/app/constants';
import {
  CriteriaType,
  FlowType,
  Lender,
  OutcomeDetails, OutcomeDetailsCard, OutcomeResponse, OutcomeResponseOverallResult,
} from 'apps/shared/src/models';
import { BasketService } from 'apps/clubhub/src/app/services';
import { CriteriaState } from 'apps/clubhub/src/app/models';
import { criteriaSteps } from '../models/criteria/criteria-steps';

@Injectable({
  providedIn: 'root',
})
export class CriteriaService {
  public outcomeDetails: OutcomeDetails = { details: [] } as {details: OutcomeResponse[]} as OutcomeDetails;
  public criteriaStep: CriteriaStep = CriteriaStep.Issues;
  public searchTerm = '';
  public loading = false;
  public lenderCount = 0;
  public criteriaLoading = false;
  public criteriaV2Enabled = true;
  public state: CriteriaState = {
    currentIssue: undefined,
    currentContext: undefined,
  };
  public step: string = criteriaSteps.criteriaSelect;
  public showBrokerDetails: boolean;
  public lender: Lender | null;

  public constructor(
    private clubHubDataService: ClubHubDataService,
    private activatedRoute: ActivatedRoute,
    @Inject(BasketService) private basketService,
  ) { }

  public getOutcomes(brokerOutcome: BrokerOutcome): Observable<OutcomeDetails> {
    return this.clubHubDataService.post<OutcomeDetails>('Outcome/GetOutcomes/', brokerOutcome);
  }

  public resetOutcomeDetails(): void {
    this.outcomeDetails.contexts = [];
    this.outcomeDetails.details = [];
  }

  public resetParamsToDefault(): void {
    this.basketService.clearBasket();
    this.resetOutcomeDetails();
  }

  public yesLenders(): OutcomeResponseOverallResult[] {
    return this.outcomeDetails.details
      .filter((x) => x.overallResult.outcomeResult as OutcomeResults === OutcomeResults.Yes)
      .sort((a, b) => a.overallResult.lender.localeCompare(b.overallResult.lender))
      .map((x) => x.overallResult);
  }

  public referLenders(): OutcomeResponseOverallResult[] {
    return this.outcomeDetails.details
      .filter((x) => x.overallResult.outcomeResult as OutcomeResults === OutcomeResults.Refer)
      .sort((a, b) => a.overallResult.lender.localeCompare(b.overallResult.lender))
      .map((x) => x.overallResult);
  }

  public noLenders(): OutcomeResponseOverallResult[] {
    return this.outcomeDetails.details
      .filter((x) => x.overallResult.outcomeResult as OutcomeResults === OutcomeResults.No)
      .sort((a, b) => a.overallResult.lender.localeCompare(b.overallResult.lender))
      .map((x) => x.overallResult);
  }

  public getEpcReportPdf(postcode: string, address: string): void {
    this.clubHubDataService
      .postFileBlob('propertyCriteria/EpcReport', { postcode, address })
      .subscribe({
        next: blob => downloadBlob(blob, `EPC-${address.replace(/[\s,]+/, '_')}-${postcode}.pdf`),
        error: () => window.open('https://find-energy-certificate.service.gov.uk'),
      });
  }

  public workOutCriteriaType(outcome: OutcomeDetailsCard) {
    if (outcome.issue.includes('Intuitive Criteria')) {
      return CriteriaType.Intuitive;
    } else if (outcome.issue.includes('Added Automatically')) {
      return CriteriaType.Property;
    }
    return CriteriaType.Manual;
  }

  public get flowType(): FlowType {
    const flowType = sessionStorage.getItem(keys.flowType);
    if (flowType) {
      return +flowType as FlowType;
    }

    throw new Error('FlowType not find in session storage');
  }

  public set flowType(flowType: FlowType) {
    sessionStorage.setItem(keys.flowType, flowType.toString());
  }

  public get isAdmin(): boolean {
    return sessionStorage.getItem(keys.isAdmin) === 'true';
  }

  public set isAdmin(value: boolean) {
    sessionStorage.setItem(keys.isAdmin, value.toString());
  }

  public get isEditMode(): boolean {
    return this.flowType === FlowType.Edit;
  }

  public get answerIds(): number[] {
    let answerIds: number[] = [];
    if (this.activatedRoute.snapshot.queryParams.answerIds) {
      if (this.activatedRoute.snapshot.queryParams.answerIds.includes(',')) {
        answerIds = this.activatedRoute.snapshot.queryParams.answerIds.split(',').map((x: string | number) => +x);
      } else if (typeof this.activatedRoute.snapshot.queryParams.answerIds !== 'string') {
        answerIds = this.activatedRoute.snapshot.queryParams.answerIds;
      } else {
        answerIds.push(+this.activatedRoute.snapshot.queryParams.answerIds);
      }
    }
    return answerIds;
  }
}
