import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FlexibleFeatures } from 'apps/shared/src/models';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { editorConfig } from '../../editor.config';

@Component({
  selector: 'app-flexible-features',
  templateUrl: 'flexible-features.component.html',
  styleUrls: ['flexible-features.component.scss'],
})

export class FlexibleFeaturesComponent implements OnInit {
  @Input() public flexibleFeatures: UntypedFormArray;
  @Input() public features: FlexibleFeatures[];
  @Input() public disabled = false;
  public items: UntypedFormArray;
  public removingItem: number | null = null;
  public editor = DecoupledEditor;
  public editorConfig = { ...editorConfig };

  public constructor(private fb: UntypedFormBuilder) {
    this.editorConfig.placeholder = 'Feature wording';
  }

  public onReady(editor) {
    editor.ui.view.editable.element.parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.view.editable.element,
    );
  }

  public ngOnInit() {
    this.features.forEach((feature) => {
      this.addItem(feature.type, feature.text);
    });
  }

  public createItem(type = '', text = ''): UntypedFormGroup {
    const formGroup = this.fb.group({
      type: new UntypedFormControl(type, Validators.pattern('^[a-zA-Z0-9 _-]+$')),
      text: text,
    });
    if (this.disabled) {
      formGroup.disable({ onlySelf: false });
    }
    return formGroup;
  }

  public addItem(type = '', text = ''): void {
    this.items = this.flexibleFeatures as UntypedFormArray;
    this.items.push(this.createItem(type, text));
  }

  public removeFee(featureIndex: number) {
    this.items.removeAt(featureIndex);
    this.removingItem = null;
  }

  public valid(name:string, index:number): boolean {
    const control = (this.items.controls[index] as UntypedFormGroup).controls[name];
    return control.valid && control.touched;
  }

  public invalid(name:string, index:number): boolean {
    const control = (this.items.controls[index] as UntypedFormGroup).controls[name];
    return control.invalid && control.touched;
  }

  public getControl(name:string, index:number) {
    return (this.items.controls[index] as UntypedFormGroup).get(name);
  }
}
