import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from '@msslib/services';
import { ProductTransferInfoModel } from 'apps/shared/src/models';
import {
  LenderProductTransferService,
} from '@msslib/services/lender-product-transfer.service';
import { hasChanges } from '@msslib/helpers/model-helper';
import cloneDeep from 'lodash-es/cloneDeep';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FieldTypes } from '@msslib/models';

@Component({
  selector: 'lib-product-transfer-details',
  templateUrl: './product-transfer-details.component.html',
  styleUrls: ['./product-transfer-details.component.css'],
})
export class ProductTransferDetailsComponent implements OnInit {

  @Input() public lenderNameId: number;
  @Output() public unsavedChangesChange = new EventEmitter<boolean>();

  private pristineModel: any;
  private hasUnsavedChanges: boolean;
  public productTransferForm: FormGroup;
  public productTransferOptions: FormlyFormOptions = {};
  public verified = false;
  public verifyError = false;
  public productTransferFields: FormlyFieldConfig[];
  public productTransferModel: ProductTransferInfoModel;

  public constructor(
    private lenderProductTransferService: LenderProductTransferService,
    private toastService: ToastService,
  ) {
  }

  public ngOnInit() {
    this.configureProductTransferForm();
    this.getProductTransfer();
  }

  public onSubmit(model: ProductTransferInfoModel) {
    this.verifyError = false;
    Object.keys(this.productTransferForm.controls).forEach((key) => {
      this.productTransferForm.controls[key].markAsDirty();
    });

    if (!this.productTransferForm.valid) {
      return;
    }

    if (model.retentionUrl && (!this.verified || !model.retentionUrlVerify)) {
      this.verifyError = true;
      return;
    }

    this.lenderProductTransferService.updateProductTransfer(this.lenderNameId, model).subscribe({
      next: () => {
        this.toastService.success('Product Transfer Information has been updated');
        this.getProductTransfer();
      },
    });
  }

  private getProductTransfer() {
    this.lenderProductTransferService.getProductTransfer(this.lenderNameId)
      .subscribe((productTransferInfoResult: ProductTransferInfoModel) => {
        this.verified = true;
        this.productTransferModel = {
          ...productTransferInfoResult,
          retentionUrlVerify: !!productTransferInfoResult.retentionUrl,
        };
        this.setPristineValues();
      });
  }

  public modelChange(currentModel: any) {
    this.hasUnsavedChanges = hasChanges(this.pristineModel, currentModel);
    this.unsavedChangesChange.emit(this.hasUnsavedChanges);
  }

  private setPristineValues() {
    this.pristineModel = cloneDeep(this.productTransferModel);
    this.hasUnsavedChanges = false;
    this.unsavedChangesChange.emit(this.hasUnsavedChanges);
  }

  private configureProductTransferForm() {
    this.productTransferForm = new FormGroup({});
    this.productTransferFields = [
      {
        key: 'supportingDocuments',
        type: FieldTypes.TextArea,
        props: {
          label: 'Required supporting documents',
          tooltip: 'Any extra documentation the applicant needs to provide for a product transfer.',
          rows: 3,
          required: true,
        },
      },
      {
        key: 'timeBeforeApplicable',
        type: FieldTypes.Number,
        props: {
          label: 'When can a customer apply for a Product Transfer?',
          tooltip:
            'How long before the end of the mortgage term can a customer change/ apply/go live' +
            ' with a product transfer.',
          addonEnd: 'Days',
          required: true,
        },
      },
      {
        key: 'additionalNotes',
        type: FieldTypes.Textbox,
        props: {
          label: 'Additional Notes for When can a customer apply for a Product Transfer',
        },
      },
      {
        key: 'whenContactCustomer',
        type: FieldTypes.Number,
        props: {
          label: 'When do you contact the customer?',
          tooltip: 'How long before the end of the mortgage term do you contact the customer.',
          addonEnd: 'Days',
          required: true,
        },
      },
      {
        key: 'processFurtherBorrowing',
        type: FieldTypes.TextArea,
        props: {
          label: 'Process for further borrowing',
          tooltip: 'What is your process for further borrowing on a product transfer.',
          rows: 3,
          required: true,
        },
      },
      {
        key: 'processMissedPayments',
        type: FieldTypes.TextArea,
        props: {
          label: 'Process for missed mortgage payments',
          tooltip: 'What is your process for missed mortgage payments during the current mortgage ' +
            'term and a customer is wanting to do a product transfer.',
          rows: 3,
          required: true,
        },
      },
      {
        key: 'retentionUrl',
        type: FieldTypes.Textbox,
        props: {
          change: (formControl: FormlyFieldConfig) => {
            this.verified = false;
            (formControl.parent?.form?.controls as any).retentionUrlVerify.setValue(false);
          },
          pattern: /https:\/\/www.*/,
          label: 'Lender retention URL (Please include the full url i.e. \'https://...)\'',
          tooltip: 'Please provide the URL link to your Product transfer page. If you do not have one, leave blank.',
          addonEndButton: {
            text: 'Verify',
            clickAction: this.openUrl.bind(this),
          },
        },
        validation: {
          messages: {
            pattern: (error: unknown, field: FormlyFieldConfig) =>
              `"${field.formControl?.value}" is not a valid URL`,
          },
        },
      },
      {
        key: 'retentionUrlVerify',
        type: FieldTypes.Checkbox,
        defaultValue: this.verified,
        props: {
          change: () => this.verifyError = false,
          label: 'I confirm the URL above is correct',
        },
        expressions: {
          'props.disabled': () => !this.verified,
        },
      },
    ];
  }

  private openUrl() {
    const url = (this.productTransferForm.value as any).retentionUrl;
    if (url) {
      this.verified = true;
      this.verifyError = false;
      (this.productTransferForm.controls as any).retentionUrlVerify.updateValueAndValidity();
      window.open(url, '_blank');
    }
  }
}
