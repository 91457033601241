import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { VariableRateCodeEditorModel } from '@msslib/models/variable-rate-codes';
import { FormsValidators } from '../forms';
import format from 'date-fns/esm/format';
import parseISO from 'date-fns/esm/parseISO';

export type VariableRateCodesEditorComponentFormType = FormArray<FormGroup<{
  code: FormControl<string | null>;
  variableRate: FormControl<number | null>;
  description: FormControl<string | null>;
  isTracker: FormControl<boolean | null>;
  currentRate: FormControl<number | null>;
  markedAsDeleted: FormControl<boolean | null>;
}>>;

@Component({
  selector: 'lib-variable-rate-codes-editor',
  templateUrl: 'variable-rate-codes-editor.component.html',
  styleUrls: ['variable-rate-codes-editor.component.scss'],
})
export class VariableRateCodesEditorComponent {

  @Input({ required: true }) public form: VariableRateCodesEditorComponentFormType;
  @Input({ required: true }) public variableRateCodes: VariableRateCodeEditorModel[];
  @Input() public scheduledDate: string | null;
  @Input() public isProductAdminView: boolean;
  @Output() public deleteVariableRateCode = new EventEmitter<number>();
  private readonly maxVariableRateCodesAllowed = 10;

  public get hasScheduled() {
    return !!this.scheduledDate;
  }

  public get scheduledDateFormatted() {
    return this.scheduledDate
      ? format(parseISO(this.scheduledDate), 'dd/MM/yyyy HH:mm')
      : '';
  }

  public get canAddNew(): boolean {
    return this.variableRateCodes &&
      this.variableRateCodes.length < this.maxVariableRateCodesAllowed && this.isProductAdminView;
  }

  public trackerChanged(index: number) {
    const form = this.form.at(index);
    const variableRate = form.controls.variableRate;
    variableRate.markAllAsTouched();
    // manually trigger validation for "variableRate" input. Needed to check "0" for Tracker rates
    variableRate.updateValueAndValidity();
  }

  public static buildForm(
    variableRateCodes: VariableRateCodeEditorModel[],
    useExistingRate: boolean = false,
    discountProductRates: {rate: number; code: string}[],
  ): VariableRateCodesEditorComponentFormType {
    return new FormArray(
      variableRateCodes.map(code => {
        return new FormGroup({
          code: new FormControl(code.code),
          description: new FormControl<string | null>(code.description, [Validators.required]),
          isTracker: new FormControl<boolean | null>(code.isTracker),
          variableRate: new FormControl<number | null>(
            useExistingRate
              ? (code.scheduledRate ?? code.currentRate)
              : null,
            useExistingRate
              ?
              [
                Validators.required,
                FormsValidators.variableRateGreaterThenZero(),
                FormsValidators.nonNegativeRatesValidator(discountProductRates),
              ]
              :
              [
                FormsValidators.variableRateGreaterThenZero(),
              ]),
          currentRate: new FormControl<number | null>(code.currentRate),
          markedAsDeleted: new FormControl<boolean | null>(false),
        });
      }));
  }

  public trackByCode({ code }: { code: string }) {
    return code;
  }

  public errors(index: number, controlName: string) {
    return this.form.get([index, controlName])?.errors;
  }

  public invalid(index: number, controlName: string) {
    const ctrl = this.form.get([index, controlName]);
    return ctrl?.touched && ctrl.invalid;
  }

  public valid(index: number, controlName: string) {
    const ctrl = this.form.get([index, controlName]);
    return ctrl?.touched && ctrl.valid;
  }

  public deleteVariableRateCodeClick(variableRateCodeIndex: number) {
    if (this.variableRateCodes[variableRateCodeIndex].addedByEditor) {
      this.form.removeAt(variableRateCodeIndex);
      this.variableRateCodes.splice(variableRateCodeIndex, 1);
    } else {
      this.deleteVariableRateCode.emit(variableRateCodeIndex);
    }
    this.updateFormControlValueAndValidity('code');
  }

  public addVariableRateCodeClick() {
    const emptyCode: VariableRateCodeEditorModel = {
      code: '',
      currentRate: null,
      scheduledRate: null,
      description: null,
      isTracker: false,
      addedByEditor: true,
    };
    const formGroup = new FormGroup({
      code: new FormControl(emptyCode.code, [Validators.required, this.uniqueVariableRateCodeValueValidator()]),
      description: new FormControl<string | null>(emptyCode.description, [Validators.required]),
      isTracker: new FormControl<boolean | null>(emptyCode.isTracker),
      variableRate: new FormControl<number | null>(emptyCode.currentRate,
        [Validators.required, FormsValidators.greaterThanOnTouched(0)]),
      currentRate: new FormControl<number | null>(emptyCode.currentRate),
      markedAsDeleted: new FormControl<boolean | null>(false),
    });
    this.form.push(formGroup);
    this.variableRateCodes.push(emptyCode);
    this.updateFormControlValueAndValidity('code');
  }

  private updateFormControlValueAndValidity(fieldName: string) {
    this.form.controls.forEach(x => x.get(fieldName)?.updateValueAndValidity());
  }

  private uniqueVariableRateCodeValueValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!this.form || !value) {
        return null;
      }
      const values = this.form.controls
        .filter(x => !x.get('markedAsDeleted')?.value)
        .map(ctrl => ctrl.get('code')?.value);
      const isDuplicate = values
        .filter(x => x?.trim().toLowerCase() === value.trim().toLowerCase()).length > 1;
      return isDuplicate ? { duplicateValue: true } : null;
    };
  }
}
