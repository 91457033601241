import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { appName } from '@msslib/constants/app-name';
import { ClientApps } from '@msslib/models';
import { AuthorizeService, ModalService } from '@msslib/services';
import { NewFeatureService } from '@msslib/services/new-feature.service';
import { NewFeatures } from '@msslib/models/new-features';
import { takeWhile } from 'rxjs';

@Component({
  selector: 'app-root',
  styleUrls: ['app.component.scss'],
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  public newFeatureOpened = false;
  public constructor(
    private authService: AuthorizeService,
    private newFeatureService: NewFeatureService,
    private modalService: ModalService,
  ) { }

  @ViewChild('rebranding') private rebranding: ElementRef<HTMLElement>;

  public ngOnInit() {
    (window as any).appName = appName.lenderHub;
    this.rebrandingPopup();
  }

  public rebrandingPopup() {
    this.authService.isAuthenticated().pipe(takeWhile((isAuthenticated) => !this.newFeatureOpened || !isAuthenticated))
      .subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this.newFeatureOpened = true;
          setTimeout(() => {
            this.newFeatureService.checkIfNewFeatureHasBeenSeen(NewFeatures.Rebranding).subscribe((res) => {
              if (!res.seen) {
                this.modalService.open({
                  title: 'Legal & General Ignite',
                  template: this.rebranding,
                  showButtons: true,
                  hideCancel: true,
                  okLabel: 'Okay',
                  client: ClientApps.LenderHub,
                }).then(() => {
                  this.newFeatureService.setUserHasSeenNewFeature(NewFeatures.Rebranding).subscribe();
                });
              }
            });
          }, 2000);
        }
      });
  }
}
