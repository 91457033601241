<div class="container lenderhub-home mt-3">
  <section class="row">
    <div class="header-image w-100">
      <img src="assets/images/lenderhub/home-page/LH-New-Banner.svg" alt="" />
    </div>
  </section>

  <section class="row">
    <div class="promo-bar col">
    <h2>
        Hello and welcome to the LenderHub. Here you will find all the information needed to make the most of your partnership with us. You
        can make sure your details on our site are up to date, amend your exclusive offers and have access to exclusive insights and MI.
      </h2>
    </div>
  </section>

  <section *ngIf="showServiceLevelUpdates" class="lenderhub-section row">
    <div class="col-12">
      <h2 class="title">Service Level Updates</h2>
    </div>
    <div class="col">
      <app-lender-service-level-updates></app-lender-service-level-updates>
    </div>
  </section>

  <section class="lenderhub-section row">
    <div class="col-12">
      <h2 class="title">Lender tools</h2>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-4 mb-4" *ngIf="!hasRole(roles.packager)">
          <div id="lendersPageUpdate" class="border fill">
            <app-lenderhub-home-card title="Lender page update">
              <p card-content>Update the details on your lender page.</p>
              <a card-actions class="btn btn-2022--secondary btn-primary-chevron" [href]="lenderDetailsUrl" target="_blank">Find out more</a>
            </app-lenderhub-home-card>
          </div>
        </div>
        <div class="col-md-4 mb-4" *ngIf="!hasRole(roles.packager)">
          <div id="marketingHub" class="border fill">
            <app-lenderhub-home-card title="Marketing Hub">
              <p card-content>Advertise your bank or building society on our website.</p>
              <a card-actions class="btn btn-2022--secondary btn-primary-chevron" href="assets/documents/2021 Marketing Brochure.pdf" download>Find out more</a>
            </app-lenderhub-home-card>
          </div>
        </div>
        <div class="col-md-4 mb-4" *ngIf="!hasRole(roles.packager) && hasRole(roles.lenderCriteria)">
          <div id="criteria" class="border fill">
            <app-lenderhub-home-card title="Criteria">
              <p card-content>Access to industry leading MI. Monitor and analyse trends within the market.</p>
              <a
                card-actions
                class="btn btn-2022--secondary btn-primary-chevron"
                [routerLink]="['/criteria-v2/dashboard']"
              >
                Find out more
              </a>
            </app-lenderhub-home-card>
          </div>
        </div>
        <div id="manageProduct" class="col-md-4 mb-4" *ngIf="hasRole(roles.product) && lenderHasProducts">
          <div class="border fill">
            <app-lenderhub-home-card title="Manage product">
              <p card-content>Upload your latest product list, manage scheduled products and switch products on/off from Legal & General Ignite.</p>
              <p card-content><b>NEW -</b> Input/update your Product Transfer information to be displayed on Legal & General Ignite.</p>
              <a card-actions class="btn btn-2022--secondary btn-primary-chevron" [routerLink]="['/product-upload']">Residential and Buy To Let</a>
              <a
                *ngIf="hasBridgingLendingType"
                card-actions
                class="btn btn-2022--secondary btn-primary-chevron mt-3"
                [routerLink]="['/bridging-product-upload']"
              >Bridging</a>
            </app-lenderhub-home-card>
          </div>
        </div>
        <div id="igniteAudit" class="col-md-4 mb-4" *ngIf="hasRole(roles.igniteAudit) && lenderHasProducts && !hasRole(roles.packager)">
          <div class="border fill">
            <app-lenderhub-home-card title="Ignite audit">
              <p card-content>Run an audit to ensure that your Affordability, Criteria and Products are returning correctly in Legal & General Ignite and view your audit history.</p>
              <a card-actions class="btn btn-2022--secondary btn-primary-chevron" [routerLink]="['/audit-history']">View audit history</a>
              <p card-actions class="pt-3">Run a Legal & General Ignite audit</p>
              <div card-actions class="row no-gutters">
                <div class="col me-2">
                  <button card-actions
                    type="button"
                    class="col btn btn-2022--secondary btn-primary-chevron"
                    [disabled]="!hasLiveProducts"
                    (click)="navigateWithAuditDisclaimer(['/ignite/affordability/home'], { scheduled: false })"
                  >
                    Live
                  </button>
                </div>
                <div class="col ms-2">
                  <button card-actions
                    type="button"
                    class="col btn btn-2022--secondary btn-primary-chevron"
                    [disabled]="!hasScheduledProducts"
                    (click)="navigateWithAuditDisclaimer(['/ignite/affordability/home'], { scheduled: true })"
                  >
                    Scheduled
                  </button>
                </div>
              </div>
            </app-lenderhub-home-card>
          </div>
        </div>
        <div id="productsAudit" class="col-md-4 mb-4" *ngIf="hasRole(roles.productsAudit) && lenderHasProducts && !hasRole(roles.packager)">
          <div class="border fill">
            <app-lenderhub-home-card title="Products audit">
              <p card-content>Run an audit to ensure that your products are returning correctly in our Product results page and view your audit history.</p>
              <a card-actions class="btn btn-2022--secondary btn-primary-chevron" [routerLink]="['/audit-history']">View audit history</a>
              <p card-actions class="pt-3">Run a Products audit</p>
              <div card-actions class="row no-gutters">
                <div class="col me-2">
                  <button card-actions
                    type="button"
                    class="col btn btn-2022--secondary btn-primary-chevron"
                    [disabled]="!hasLiveProducts"
                    (click)="navigateWithAuditDisclaimer(['/ignite/products'], { scheduled: false })"
                  >
                    Live
                  </button>
                </div>
                <div class="col ms-2">
                  <button card-actions
                    type="button"
                    class="col btn btn-2022--secondary btn-primary-chevron"
                    [disabled]="!hasScheduledProducts"
                    (click)="navigateWithAuditDisclaimer(['/ignite/products'], { scheduled: true })"
                  >
                    Scheduled
                  </button>
                </div>
              </div>
            </app-lenderhub-home-card>
          </div>
        </div>
        <div id="manageAffordability" class="col-md-4 mb-4" *ngIf="hasRole(roles.affordabilityManager) && !hasRole(roles.packager)">
          <div class="border fill">
            <app-lenderhub-home-card title="Manage affordability">
              <p card-content>View and Update the payrate used to calculate Affordability in Legal & General Ignite.</p>
              <a card-actions class="col btn btn-2022--secondary btn-primary-chevron" [routerLink]="['/manage-affordability/RES']">Residential</a>
              <a card-actions class="col btn btn-2022--secondary btn-primary-chevron mt-3" [routerLink]="['/manage-affordability/BTL']">Buy To Let</a>
            </app-lenderhub-home-card>
          </div>
        </div>
        <div id="viewAllProducts" class="col-md-4 mb-4" *ngIf="hasRole(roles.product) && lenderHasProducts">
          <div class="border fill">
            <app-lenderhub-home-card title="View all products">
              <p card-content>View your full list of products that are currently Live on Legal & General Ignite.</p>
              <a card-actions class="btn btn-2022--secondary btn-primary-chevron" [routerLink]="['/all-products']">View All Residential and BTL products</a>
              <a *ngIf="hasBridgingLendingType" card-actions class="btn btn-2022--secondary btn-primary-chevron mt-3" [routerLink]="['/bridging-all-products']">Bridging</a>
            </app-lenderhub-home-card>
          </div>
        </div>
        <div id="documentsInformation" class="col-md-4 mb-4" *ngIf="hasRole(roles.lenderEsisConfiguration) && !hasRole(roles.packager)">
          <div class="border fill">
            <app-lenderhub-home-card title="Add Documents information">
              <p card-content>View or edit your Documents information.</p>
              <a card-actions class="col btn btn-2022--secondary btn-primary-chevron" [routerLink]="['/esis']">Add Documents information</a>
            </app-lenderhub-home-card>
          </div>
        </div>
        <div id="consumerDuty" class="col-md-4 mb-4" *ngIf="hasRole(roles.consumerDuty)">
          <div class="border fill">
            <app-lenderhub-home-card title="Fair Value Statements">
              <p card-content>Add, View or Remove your documents</p>
              <a card-actions class="col btn btn-2022--secondary btn-primary-chevron" [routerLink]="['/consumer-duty']">Add Documents</a>
            </app-lenderhub-home-card>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="row mb-5">
    <div class="col box-green">
      <div class="row box-inner">
        <div class="col">
          <h2>Contact us</h2>
        </div>
        <div class="col link-wrapper">
          <a [routerLink]="['/contact']" class="btn btn-default btn-default-chevron">
            <span>Contact Us</span>
            <i class="fa fa-chevron-right"></i>
          </a>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- Ignite/Products audit disclaimer -->
<ng-template #btnModalRef>
  <div class="text-center">
    <p>By selecting 'Proceed', you indicate that you will use the Audit Function solely for the purpose of assessing the accuracy of Products.</p>
    <button type="button" class="btn btn-outline-2022--secondary w-100 h-100" (click)="navigateToLink(addScheduledParam, addLinkParam)">
      Proceed
    </button>
  </div>
</ng-template>
