import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FeeCalculationScenario,
  ProductFeeType,
  feeDisplayModel,
} from '@msslib/constants/product-fees';
import { CustomFee, CustomFeeDisplayModel, CustomProductCalculations }
  from '@msslib/models/custom-product-calculations';
import { ModalStateService } from '@msslib/services/modal-state.service';
import { ModalService } from '@msslib/services/modal.service';
import { ProductsSearchService } from 'apps/clubhub/src/app/ignite/services';

@Component({
  selector: 'lib-product-fees-modal',
  templateUrl: './product-fees-modal.component.html',
  styleUrls: ['./product-fees-modal.component.scss'],
})
export class ProductFeesModalComponent implements OnInit {
  public allFees: Partial<CustomFeeDisplayModel>[];
  private nonDigitNumericChars = ['e','E', '+', '-', '.'];
  public eFeeCalculationScenarios = FeeCalculationScenario;
  public form: FormGroup;
  private disabledFees: ProductFeeType[] = [ProductFeeType.Arrangement, ProductFeeType.Chaps];

  @Output() public customProductCalculations = new EventEmitter<CustomProductCalculations>();

  public constructor(
    private modalService: ModalService,
    private modalStateService: ModalStateService,
    private formBuilder: FormBuilder,
    private productsSearchService: ProductsSearchService,
  ) { }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      calculateInitialTrueCostOverMonths: [
        {
          value: this.customProductCalculationsSettings.calculateInitialTrueCostOverMonths,
          disabled: this.customProductCalculationsSettings.isCalculateInitialTrueCostOverMonthsOverridable === false,
        },
        [Validators.required, Validators.min(6), Validators.max(1000)],
      ],
      deductCashback: [
        {
          value: this.customProductCalculationsSettings.deductCashback,
          disabled: this.customProductCalculationsSettings.isDeductCashbackOverridable === false,
        },
      ],
      customFeeCalculationScenarios: this.formBuilder.array([]),
    });

    this.allFees = feeDisplayModel.filter(fee =>fee.type !== ProductFeeType.Broker);

    this.patchFormValues();
  }

  private get customProductCalculationsSettings() {
    return this.productsSearchService.customProductCalculations;
  }

  public get assumedLegalFee(): number | null {
    return this.customProductCalculationsSettings?.assumedLegalFeeCost;
  }

  private set customProductCalculationsSettings(value: CustomProductCalculations) {
    this.productsSearchService.customProductCalculations = value;
  }

  private patchFormValues() {
    this.allFees
      .forEach((customFee: CustomFeeDisplayModel) => {
        const feeSetting = this.customProductCalculationsSettings.customFeeCalculationScenarios
          .find(x=> x.feeName === customFee.customName && x.feeType === customFee.type);
        this.feeArrayControl.push(this.formBuilder.group({
          feeCalculationScenario:
          [
            {
              value: feeSetting?.feeCalculationScenario,
              disabled: feeSetting?.canOverride === false,
            },
          ],
          feeName: feeSetting?.feeName,
          feeType: feeSetting?.feeType,
        }));
      });

    this.customProductCalculationsSettings.customFees
      ?.filter(customFee => customFee.feeName !== 'Broker Fee')
      .forEach((customFee: CustomFee) => {
        this.allFees.push(
          {type: ProductFeeType.Custom, customName: customFee.feeName, displayName: customFee.feeName});

        const feeSetting = this.customProductCalculationsSettings.customFeeCalculationScenarios
          .find(x=>x.feeName === customFee.feeName);
        this.feeArrayControl.push(this.formBuilder.group({
          feeCalculationScenario:
            [
              {
                value: feeSetting?.feeCalculationScenario,
                disabled: feeSetting?.canOverride === false,
              },
            ],
          feeName: feeSetting?.feeName,
          feeType: feeSetting?.feeType,
        }));
      });

    if (this.assumedLegalFee) {
      this.allFees.push(
        {customName: 'Assumed Legal Fee', type: ProductFeeType.Custom, displayName: 'Assumed Legal Fee'});
      this.feeArrayControl.push(this.formBuilder.group({
        feeCalculationScenario:
        [
          {
            value:  this.customProductCalculationsSettings.customFeeCalculationScenarios
              ?.find(customFee => customFee.feeName.toString() === 'Assumed Legal Fee')?.feeCalculationScenario,
            disabled: true,
          },
        ],
        feeName: 'Assumed Legal Fee',
        feeType: ProductFeeType.Custom,
      }));
    }
    if (this.modalStateService.options.data?.model) {
      this.form.patchValue(this.modalStateService.options.data.model);
    }
  }

  public update(): void {
    if (!this.customTrueCostPeriodControl.disabled) {
      this.customProductCalculationsSettings.calculateInitialTrueCostOverMonths =
        this.customTrueCostPeriodControl.value;
    }

    if (!this.deductCashbackControl.disabled) {
      this.customProductCalculationsSettings.deductCashback = this.deductCashbackControl.value;
    }

    this.customProductCalculationsSettings.customFeeCalculationScenarios
      .filter(customFee => customFee.feeName !== 'Broker Fee')
      .map(fee => {
        const formControl = this.feeArrayControl.controls
          .find(control => control.get('feeName')?.value === fee.feeName &&
            control.get('feeType')?.value === fee.feeType);
        if (!formControl?.disabled) {
          fee.feeCalculationScenario = formControl?.get('feeCalculationScenario')?.value;
        }
      });

    this.customProductCalculations.emit(this.customProductCalculationsSettings);
    this.productsSearchService.customProductCalculations = this.customProductCalculationsSettings;
    this.modalService.dismiss();
  }

  public preventUnwanted(event) {
    if (this.nonDigitNumericChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  public get customTrueCostPeriodControl(): FormControl {
    return this.form.get('calculateInitialTrueCostOverMonths') as FormControl;
  }

  public get feeArrayControl(): FormArray {
    return this.form.get('customFeeCalculationScenarios') as FormArray;
  }

  private get deductCashbackControl(): FormControl {
    return this.form.get('deductCashback') as FormControl;
  }

  public selectAllFees(scenario: FeeCalculationScenario) {
    this.feeArrayControl.controls.forEach(control => {
      if ((this.disabledFees.includes(control.value.feeType) &&
        scenario === FeeCalculationScenario.Exclude) ||
        !this.customProductCalculationsSettings.customFeeCalculationScenarios
          ?.find(x=>x.feeType === control.value.feeType && x.feeName === control.value.feeName)?.canOverride) {
        return;
      }
      control.setValue(
        {feeCalculationScenario: scenario, feeName: control.value.feeName, feeType: control.value.feeType});
    });
  }

  public isFeeDisabled(feeType: ProductFeeType): boolean {
    return this.disabledFees.includes(feeType);
  }
}
