import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ESISService } from 'apps/lenderhub/src/app/services';
import { ESISInformation, LendingDocumentInformationFormType, LendingDocumentInformationType }
  from 'apps/shared/src/models';
import { AuthorizeService, ModalService, ToastService } from '@msslib/services';
import { ComponentCanDeactivate } from '@msslib/services/guards/pendingChanges.guard';
import { LendingTypeService } from '@msslib/services/lending-type.service';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, forkJoin } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

enum AccordionNames {
  Introduction = 'introduction',
  LenderDetails = 'lenderDetails',
  MainFeaturesOfLoan = 'mainFeaturesOfLoan',
  CostsToBePaid = 'costsToBePaid',
  InterestRatesOtherCosts = 'interestRatesOtherCosts',
  FrequencyNumberPayments = 'frequencyNumberPayments',
  AmountEachInstalment = 'amountEachInstalment',
  ExternalReferenceRate = 'externalReferenceRate',
  AdditionalObligations = 'additionalObligations',
  FlexibleFeatures = 'flexibleFeatures',
  IncentivesAdditionalFeatures = 'incentivesAdditionalFeatures',
  OtherRightsOfBorrower = 'otherRightsOfBorrower',
  EarlyRepaymentCharges = 'earlyRepaymentCharges',
  NonCompliance = 'nonCompliance',
  AdditionalInformation = 'additionalInformation',
  IllustrativeRepaymentTable = 'illustrativeRepaymentTable',
}

interface AccordionDefinition {
  title: string;
  name: string;
  opened: boolean;
  canOpen?: boolean;
  condition?: () => boolean;
  subAccordions?: AccordionDefinition[];
}

@Component({
  selector: 'app-esis',
  templateUrl: 'esis.component.html',
  styleUrls: ['esis.component.scss'],
})
export class ESISComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('esisPreviewModal') public esisPreviewModal: TemplateRef<void>;
  public esisForm: UntypedFormGroup;
  public showForm = false;
  public isEditMode = false;
  public esisResult: ESISInformation;
  private alphaNumericValidation = '^[a-zA-Z0-9 _/:.-]+$';
  private telephoneNumberValidation = '^[ 0-9]{8,15}$';
  private missingEsisLendingTypes: string[];
  public availableFormTypes: (LendingDocumentInformationFormType & { text: string; value: number })[];
  private _accordions: AccordionDefinition[] = [
    {
      title: 'Introduction',
      opened: false,
      name: AccordionNames.Introduction,
      condition: () => this.currentDocumentType === LendingDocumentInformationType.Illustration,
    },
    {
      title: '1. Lender Details',
      opened: false,
      name: AccordionNames.LenderDetails,
    },
    {
      title: '3. Main Features of the loan',
      opened: false,
      name: AccordionNames.MainFeaturesOfLoan,
      condition: () => this.currentDocumentType === LendingDocumentInformationType.Illustration,
    },
    {
      title: '4. Interest Rates and Other Costs',
      opened: false,
      name: AccordionNames.InterestRatesOtherCosts,
      subAccordions: [
        {
          title: '4.1 External Reference Rate',
          opened: false,
          name: AccordionNames.ExternalReferenceRate,
        },
        {
          title: '4.2 Costs to be paid on a one-off basis',
          opened: false,
          name: AccordionNames.CostsToBePaid,
        },
        {
          title: '4.3 Interest Rates and Other Costs',
          opened: false,
          name: AccordionNames.InterestRatesOtherCosts,
        },
      ],
    },
    {
      title: '5. Frequency and Number of payments',
      opened: false,
      name: AccordionNames.FrequencyNumberPayments,
      condition: () => this.currentDocumentType === LendingDocumentInformationType.Illustration,
    },
    {
      title: '6. Amount of Each Instalment',
      opened: false,
      name: AccordionNames.AmountEachInstalment,
      condition: () => this.currentDocumentType === LendingDocumentInformationType.Illustration,
    },
    {
      title: '7. Additional Obligations',
      opened: false,
      name: AccordionNames.AdditionalObligations,
    },
    {
      title: '8. Early repayment',
      opened: false,
      name: AccordionNames.EarlyRepaymentCharges,
    },
    {
      title: '9. Flexible Features',
      opened: false,
      name: AccordionNames.FlexibleFeatures,
      subAccordions: [
        {
          title: '9.1 Flexible Features',
          opened: false,
          name: AccordionNames.FlexibleFeatures,
        },
        {
          title: '9.2 Incentives / Additional Features',
          opened: false,
          name: AccordionNames.IncentivesAdditionalFeatures,
        },
      ],
    },
    {
      title: '10. Other rights of the borrower',
      opened: false,
      name: AccordionNames.OtherRightsOfBorrower,
    },
    {
      title: '12. Non-compliance with the commitments linked to the loan: consequences for the borrower',
      opened: false,
      name: AccordionNames.NonCompliance,
    },
    {
      title: '13. Additional Information',
      opened: false,
      name: AccordionNames.AdditionalInformation,
    },
    {
      title: '14. Illustrative Repayment Table',
      opened: false,
      canOpen: false,
      name: AccordionNames.IllustrativeRepaymentTable,
      condition: () => this.currentDocumentType === LendingDocumentInformationType.Illustration,
    },
  ];
  public accordionNames = AccordionNames;
  public selectedFormIndex = 0;
  private documentTypeName = [, 'ESIS', 'Illustration'];

  @HostListener('window:beforeunload', ['$event'])
  public unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue =
        'WARNING: You have unsaved changes. ' +
        'Press Cancel to go back and save these changes, or OK to lose these changes.';
    }
  }
  public canDeactivate(): Observable<boolean> | boolean {
    return this.esisForm.untouched;
  }

  public canDeactivateMessage() {
    // eslint-disable-next-line no-alert
    return confirm('WARNING: You have unsaved changes. ' +
      'Press Cancel to go back and save these changes, or OK to lose these changes.');
  }

  public constructor(
    private toastService: ToastService,
    private esisService: ESISService,
    private lendingTypeService: LendingTypeService,
    private authService: AuthorizeService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private modalService: ModalService,
  ) {}

  public ngOnInit(): void {
    this.getLendingFormTypes();
    this.route.queryParamMap.subscribe((params) => {
      this.missingEsisLendingTypes = params.getAll('missingEsisLendingTypes');
    });
  }

  public get lenderUser(): string | null {
    return this.authService.user?.lender_name as string ?? null;
  }

  private getLendingFormTypes(): void {
    forkJoin([this.esisService.getLendingFormTypes(), this.lendingTypeService.getLendingTypes()])
      .subscribe(([formTypes]) => {
        this.availableFormTypes = formTypes.map((type, idx) => ({
          ...type,
          text: this.getLendingFormTitle(type.lendingTypeId, type.documentType),
          value: idx,
        }));

        this.setSelectedFormIndex();
        this.getEsisInformation(false);
      });
  }

  public selectFormType(formTypeIndex: number): void {
    if (this.esisForm.get(AccordionNames.CostsToBePaid)?.touched) {
      const index = this.missingEsisLendingTypes.indexOf(this.currentLendingFormTitle, 0);
      if (index > -1) {
        this.missingEsisLendingTypes.splice(index, 1);
      }
    }
    this.showForm = false;
    this.selectedFormIndex = formTypeIndex;
    this._accordions.forEach(a => a.opened = false);
    this.getEsisInformation(false);
  }

  private getEsisInformation(getDraft: boolean, enableEditing = false): void {
    this.esisService.getESISInformation(this.currentLendingTypeId, this.currentDocumentType, getDraft)
      .subscribe((esisResult) => {
        this.esisResult = esisResult;
        this.esisForm = this.fb.group({
          introduction: this.fb.group({
            text: esisResult.introduction?.text ?? '',
          }),
          lenderDetails: this.fb.group({
            lenderName: new UntypedFormControl(esisResult.lenderDetails?.lenderName ?? '',
              Validators.pattern(this.alphaNumericValidation)),
            buildingName: new UntypedFormControl(esisResult.lenderDetails?.buildingName ?? '',
              Validators.pattern(this.alphaNumericValidation)),
            city: new UntypedFormControl(esisResult.lenderDetails?.city ?? '',
              Validators.pattern(this.alphaNumericValidation)),
            state: new UntypedFormControl(esisResult.lenderDetails?.state ?? '',
              Validators.pattern(this.alphaNumericValidation)),
            country: new UntypedFormControl(esisResult.lenderDetails?.country ?? '',
              Validators.pattern(this.alphaNumericValidation)),
            postcode: new UntypedFormControl(esisResult.lenderDetails?.postcode ?? '',
              Validators.pattern(this.alphaNumericValidation)),
            telephone: new UntypedFormControl(esisResult.lenderDetails?.telephone ?? '',
              Validators.pattern(this.telephoneNumberValidation)),
          }),
          mainFeaturesOfLoan: this.fb.group({
            text: esisResult.mainFeaturesOfLoan?.text ?? '',
          }),
          costsToBePaid: this.fb.array([]),
          interestRatesOtherCosts: this.fb.group({
            text: esisResult.interestRatesOtherCosts?.text ?? '',
            costsNotKnownToLender: esisResult.interestRatesOtherCosts?.costsNotKnownToLender ?? '',
          }),
          externalReferenceRate: new UntypedFormControl(esisResult.externalReferenceRate, [
            Validators.required,
            Validators.min(0),
            Validators.max(100),
          ]),
          frequencyNumberPayments: this.fb.group({
            text: esisResult.frequencyNumberPayments?.text ?? '',
          }),
          amountEachInstalment: this.fb.group({
            text: esisResult.amountEachInstalment?.text ?? '',
          }),
          additionalObligations: this.fb.group({
            text: esisResult.additionalObligations?.text ?? '',
          }),
          linkedSavings: this.fb.group({
            text: esisResult.linkedSavings?.text ?? '',
          }),
          springBoard: this.fb.group({
            text: esisResult.springBoard?.text ?? '',
          }),
          flexibleFeatures: this.fb.array([]),
          incentivesAdditionalFeatures: this.fb.array([]),
          otherRightsOfBorrower: this.fb.group({
            reflectionPeriod: new UntypedFormControl(esisResult.otherRightsOfBorrower?.reflectionPeriod ?? '',
              Validators.pattern('^[0-9]+$')),
            otherRights: esisResult.otherRightsOfBorrower?.otherRights ?? '',
          }),
          earlyRepaymentCharges: this.fb.array([]),
          earlyRepaymentChargeTerms: this.fb.group({
            text: esisResult.earlyRepaymentChargeTerms?.text ?? '',
          }),
          nonCompliance: this.fb.group({
            text: esisResult.nonCompliance?.text ?? '',
          }),
          nonComplianceCI: this.fb.group({
            text: esisResult.nonComplianceCi?.text ?? '',
          }),
          nonComplianceIO: this.fb.group({
            text: esisResult.nonComplianceIo?.text ?? '',
          }),
          additionalInformation: this.fb.group({
            text: esisResult.additionalInformation?.text ?? '',
          }),

          // This form group is only relevant for BTL illustrations
          sectionsEnabled: this.fb.group(
            Object.fromEntries(
              Object.entries(esisResult.sectionsEnabled).map(([key, enabled]) => ([key, new FormControl(enabled)])),
            ),
          ),
        });

        this.setIsEditMode(enableEditing);

        this.showForm = true;

        if (this.feesMissingInEsis) {
          this.toggleSectionOpen(AccordionNames.CostsToBePaid);
        }
      });
  }

  public get title(): string {
    const docName = {
      [LendingDocumentInformationType.Esis]: 'ESIS DATA FORM',
      [LendingDocumentInformationType.Illustration]: 'ILLUSTRATION DATA FORM',
    }[this.currentDocumentType];

    const draftSuffix = this.isEditMode ? ' - Draft Version' : '';

    return `${docName}${draftSuffix}`;
  }

  public get accordions(): AccordionDefinition[] {
    return this._accordions.filter(a => a.condition?.() ?? true);
  }

  private get currentLendingTypeId(): number {
    return this.availableFormTypes[this.selectedFormIndex].lendingTypeId;
  }

  public get currentDocumentType(): LendingDocumentInformationType {
    return this.availableFormTypes[this.selectedFormIndex]?.documentType;
  }

  public get isIllustration(): boolean {
    return this.currentDocumentType === LendingDocumentInformationType.Illustration;
  }

  public setIsEditMode(value: boolean) {
    this.isEditMode = value;
    this.esisForm[value ? 'enable' : 'disable']();
  }

  public get currentDocumentTypeName() {
    return this.documentTypeName[this.currentDocumentType];
  }

  public get previewButtonEnabled() {
    // If the user is in edit mode, we allow them to preview if the form is valid.
    if (this.isEditMode) {
      return this.esisForm.valid;
    }

    // If the user is not in edit mode, they must be viewing the live inputs.
    // Either these are filled in (and therefore valid else they couldn't have been set live) or not filled in at all.
    return !!this.esisResult?.lenderDetails?.lenderName;
  }

  public getButtonTooltip(buttonType: 'set live' | 'preview'): string | null {
    return `Please complete all sections in the ${this.currentDocumentTypeName} to ${buttonType}.`;
  }

  public hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }

  public showSectionToggle(name: string): boolean {
    // ESIS document types should never show the toggles, regardless of their enabled state
    return this.currentDocumentType !== LendingDocumentInformationType.Esis
      && this.esisForm.get(['sectionsEnabled', name]) !== null;
  }

  public isSectionEnabled(name: string | undefined): boolean {
    // ESIS document types should always show sections, regardless of anything else.
    if (!name) {
      return false;
    }
    return this.currentDocumentType === LendingDocumentInformationType.Esis
      || (this.esisForm.get(['sectionsEnabled', name])?.value ?? true);
  }

  public setSectionEnabled(name: string, enabled: boolean): void {
    this.esisForm.get(['sectionsEnabled', name])?.setValue(enabled);
    this.esisForm.markAsDirty({ onlySelf: true });
    const accordion = this._accordions.find(x => x.name === name);
    if (accordion) {
      accordion.opened = false;
    }
  }

  public isSectionOpen(name: string) {
    const section = this._accordions.find(x => x.name === name);
    return this.isSectionEnabled(name) && section?.opened && section.canOpen !== false;
  }

  public isSubsectionOpen(name: string) {
    const section = this._accordions.flatMap(x=>x.subAccordions).find(s => s?.name === name);
    return this.isSectionEnabled(name) && section?.opened && section.canOpen !== false;
  }

  public toggleSectionOpen(name: string): void {
    const section = this._accordions.find(x => x.name === name);
    if (section && this.isSectionEnabled(section?.name) && section?.canOpen !== false) {
      section.opened = !section.opened;
    }
  }

  public toggleSubsectionOpen(name: string): void {
    const section = this._accordions.flatMap(x=>x.subAccordions).find(x => x?.name === name);
    if (section && this.isSectionEnabled(section?.name) && section?.canOpen !== false) {
      section.opened = !section.opened;
    }
  }

  public edit() {
    this.getEsisInformation(true, true);
  }

  public onSubmit(setLive = false): void {
    this.esisForm.markAllAsTouched();
    if (this.esisForm.valid) {
      this.esisService.saveESISInformation(
        this.currentLendingTypeId,
        this.currentDocumentType,
        this.esisForm.value,
        setLive,
      ).subscribe(() => {
        this.toastService.success(`Form has been successfully ${setLive ? 'set live' : 'saved'}`);
        this.esisForm.markAsPristine();
        this.esisForm.markAsUntouched();

        // Revert to readonly non-draft mode
        if (setLive) {
          this.setIsEditMode(false);
        }
      });
    }
  }

  public discardChanges() {
    // Disable the form, delete the draft, then reload the form in readonly mode
    this.setIsEditMode(false);
    this.esisService.deleteDraftESIS(this.currentDocumentType, this.currentLendingTypeId)
      .subscribe(() => this.getEsisInformation(false));
  }

  public isSectionInvalid(name: string): boolean | undefined {
    const { invalid, touched } = this.esisForm.get(name) ?? {};
    return invalid && touched;
  }

  private getLendingFormTitle(lendingTypeId: number, documentType: LendingDocumentInformationType): string {
    const lendingTypeName = this.lendingTypeService.getLendingTypeById(lendingTypeId)?.name;
    const documentTypeName = this.documentTypeName[documentType];
    return `${lendingTypeName} ${documentTypeName}`;
  }

  private get currentLendingFormTitle(): string {
    const lendingTypeName = this.lendingTypeService.getLendingTypeById(this.currentLendingTypeId)?.name ?? '';
    const documentTypeName = this.documentTypeName[this.currentDocumentType];

    return `${lendingTypeName} ${documentTypeName}`;
  }

  public get feesMissingInEsis(): boolean {
    return this.missingEsisLendingTypes.includes(this.currentLendingFormTitle);
  }

  private setSelectedFormIndex(): void {
    if (!this.missingEsisLendingTypes) {
      this.selectedFormIndex = 0;
    }
    const formTypeTexts = this.availableFormTypes.map(type => type.text);

    const resiIndex = formTypeTexts.indexOf('Residential ESIS');
    const btlEsisIndex = formTypeTexts.indexOf('Buy To Let ESIS');
    const btlIllustrationIndex = formTypeTexts.indexOf('Buy To Let Illustration');

    if (resiIndex > -1 && this.missingEsisLendingTypes.includes('Residential ESIS')) {
      this.selectedFormIndex = resiIndex;
    } else if (btlEsisIndex > -1 && this.missingEsisLendingTypes.includes('Buy To Let ESIS')) {
      this.selectedFormIndex = btlEsisIndex;
    } else if (btlIllustrationIndex > -1 && this.missingEsisLendingTypes.includes('Buy To Let Illustration')) {
      this.selectedFormIndex = btlIllustrationIndex;
    }
  }

  public showPreviewModal() {
    if (this.isEditMode) {
      this.esisForm.markAllAsTouched();
    }

    if (!this.previewButtonEnabled) {
      return;
    }

    this.modalService
      .open({
        component: this.esisPreviewModal,
        size: '75',
        sticky: true,
      }).then(() => null, () => null);
  }
}
